function Folder() {
    this.id = "-2";
    this.history = true;
    this.selectMultiples = false;
    this.type = "";
    this.loading = false;
}


Folder.prototype = {
    constructor: Folder,
    update: function() {
        this.navigate(this.id);
    },
    navigate: function(id) {
        var that = this;
        if (this.loading) {
            return;
        }

        if (this.id != id && this.history) {
            window.history.pushState({folder: id}, "Folder: "+id, "admin/"+this.type+"/f/"+id);
            window.onpopstate = function(event) {
                if (event != null && event.state != null && event.state.hasOwnProperty("folder")) {
                    that.id = event.state.folder;
                    that.update();                    
                } else {
                    window.history.back();
                }
            }
        }

        this.id = id;
        var listUrl = this.getUrl("list");
        // Load folder
        
        this.startLoad();
        
        $.ajax({
            url: listUrl,
            method: "POST",
            data: {
                "id": this.id
            },
            success: function success(response) {
                that.endLoad();
                that.displayFolder(response);
            },
            error: function error() {
                that.endLoad();
                var notify = new Notification();
                notify.error("Verbindung zum Server fehlgeschlagen");
            }
        });
    },
    createFolder: function(name, callback) {
        var that = this;
        // Create folder
        $.ajax({
            url: `admin/folders/api/${that.type}/create`,
            method: "POST",
            data: {
                "name": name,
                "parent": this.id
            },
            success: function success(response) {                
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    moveFolder: function(id, dest, callback) {
        var that = this;
        $.ajax({
            url: `admin/folders/api/${that.type}/move`,
            method: "POST",
            data: {
                "destination": dest,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    copyFolder: function(id, dest, callback) {
        var that = this;
        $.ajax({
            url: `admin/folders/api/${that.type}/copy`,
            method: "POST",
            data: {
                "destination": dest,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    renameFolder: function(id, newname, callback) {
        var that = this;
        $.ajax({
            url: `admin/folders/api/${that.type}/rename`,
            method: "POST",
            data: {
                "name": newname,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    removeFolder: function(id, callback) {
        var that = this;
        $.ajax({
            url: `admin/folders/api/${that.type}/delete`,
            method: "POST",
            data: {
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    },
    makeFolderDiv: function(folder) {
        return `
            <div class="folder_folder" id="folder_${folder.id}" type="folder" folder="${folder.id}" title="${folder.name}">
                <div class="folder_content">
                    <img src="core/pictures/img/folder.png" />
                    <div class="folder_folder_name" id="folder_name_${folder.id}">${folder.name}</div>
                </div>
            </div>
        `;
    },
    makeParentDiv: function(id) {        
        return `
            <div class="folder_folder" type="parent" folder="${id}">
                <div class="folder_content">
                    <img src="core/pictures/img/parent.png" />
                    Übergeordnetes Verzeichnis
                </div>
            </div>
        `;
    },
    
    getUrl: function(fct) {
        var url = `admin/folders/api/${this.type}/${fct}`;
        /*if (this.type != "pictures" && this.type != "documents") {
            url = "api/folders/${fct}/${this.type}";
        }*/
        return url;
    },
    
    startLoad: function() {        
        return;
    },
    
    endLoad: function() {
        return;
    }
}